import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, message, Form } from "antd";
import PhoneInput from "react-phone-input-2";
import ApiCalls from "../../apis/ApiCalls";
import { NavLink, useParams, useHistory } from "react-router-dom";
import { Main } from "../UpdateProfilePatient/styledComponents";
import {
  FormWrapper,
  StyledFormItem,
  LogoContainer,
  FormHeading,
  WelcomeText,
} from "../signup/styledComponents";
import PrimaryButton from "../../components/PrimaryButton/index";
import TextLogo from "../../assets/icons/Logo/TextLogo";

const CustomCol = styled(Col)``;

const FormStyle = styled(Form)`
  width: 100%;
`;

const ReverifyUser = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [phoneNumberFormat, setPhoneNumberFormat] = useState("");
  const { userType } = useParams();
  const history = useHistory();

  const onFinish = (values) => {
    values.phoneNumber = values.phoneNumber.replace(
      phoneNumberFormat.dialCode,
      `${phoneNumberFormat.dialCode}-`
    );
    setLoading(true);
    ApiCalls.reverifyUser(values)
      .then((response) => {
        if (response.data.status) {
          history.push(
            `/user/patient/signup/otp-verification/${response.data.data.token}`
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  return (
    <Main login={true}>
      <Row>
        <LogoContainer>
          <TextLogo />
        </LogoContainer>
      </Row>
      <Row style={{ marginBottom: "114px" }}>
        <Col span={24}>
          <FormWrapper>
            <FormHeading>Verify Phone Number</FormHeading>
            <WelcomeText>
              Please enter your phone number. You will receieve an OTP for the
              phone number verification.
            </WelcomeText>
            <FormStyle
              layout="vertical"
              form={form}
              initialValues={{
                layout: "vertical",
              }}
              onFinish={onFinish}
            >
              <Row>
                <CustomCol span={24}>
                  <StyledFormItem
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Phone number is required",
                      },
                    ]}
                  >
                    <PhoneInput
                      placeholder="Mobile"
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                      }}
                      country="in"
                      onChange={(_, format) => {
                        setPhoneNumberFormat(format);
                      }}
                    />
                  </StyledFormItem>
                </CustomCol>
              </Row>

              <Row>
                <Col span={24}>
                  <StyledFormItem>
                    <PrimaryButton
                      type="primary"
                      block={true}
                      htmlType="submit"
                      loading={loading}
                      label="Verify"
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </FormStyle>
            <Row>
              <NavLink
                to={`/user/${userType}/login`}
                style={{
                  paddingLeft: "30px",
                  marginBottom: "20px",
                  color: "#4385C6",
                  fontFamily: "Gilroy Medium",
                }}
              >
                Back to login
              </NavLink>
            </Row>
          </FormWrapper>
        </Col>
      </Row>
    </Main>
  );
};

export default ReverifyUser;
