import React from "react";
import styled from "styled-components";

const ReadTimeBadge = styled.div`
  background-color: #e3e9f9;
  color: #66779e;
  padding: 2px 10px;
  border-radius: 24px;
  font-size: 10px;
  font-weight: 500;
  font-family: "Schibsted Grotesk";
  cursor: default;
  width: fit-content;
`;
const CustomTimeChip = ({ time }) => {
  return <ReadTimeBadge className="ReadTimeBadge">{time}</ReadTimeBadge>;
};

export default CustomTimeChip;
