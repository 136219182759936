import { Button } from "antd";
import React from "react";
import styled from "styled-components";

export const ReadNowButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  color: ${(props) =>
    props.closeBtn ? "rgba(66, 88, 137, 1)" : "rgba(255, 255, 255, 1)"};
  background: ${(props) =>
    props.closeBtn ? "rgba(244, 247, 252, 1)" : "rgba(66, 88, 137, 1)"};
  border: 1px solid
    ${(props) =>
      props.closeBtn ? "rgba(244, 247, 252, 1)" : "rgba(66, 88, 137, 1)"};
  cursor: pointer;
  letter-spacing: 0.5px;
  border-radius: 4px;
  font-family: "Schibsted Grotesk";
  padding: 9px 40px;
`;
const CustomArticleButton = ({
  type,
  htmlType,
  disabled,
  buttonText,
  onClick,
  className,
  closeBtn,
}) => {
  return (
    <ReadNowButton
      htmlType={htmlType}
      onClick={onClick}
      className={className}
      closeBtn={closeBtn}
      type={type}
      disabled={disabled}
    >
      {buttonText}
    </ReadNowButton>
  );
};

export default CustomArticleButton;
