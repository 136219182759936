import axiosInstance from "./AxiosInterceptor";
import axios from "axios";
import storage from "../storage";
import { API_SERVER } from "./ServerConfig";

const Axios = axios.create({
  baseURL: API_SERVER,
});
export default class ApiCalls {
  static patientLogin(data) {
    return axiosInstance({
      handlerEnabled: false,
      url: "/registration/patient/login",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static doctorLogin(data) {
    return axiosInstance({
      handlerEnabled: false,
      url: "/registration/provider/login",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }
  static corporateLogin(data) {
    return axiosInstance({
      handlerEnabled: false,
      url: "registration/company/login",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static corporateLogout() {
    return axiosInstance({
      handlerEnabled: false,
      url: "public/company/logout",
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static patientRegister(data) {
    return axiosInstance({
      handlerEnabled: false,
      url: "/registration/patient/signup",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static verifyOtp(data) {
    var pageURL = window.location.href;
    var lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);

    return Axios({
      url: "/user/verify/otp",
      headers: {
        Authorization: "Bearer" + lastURLSegment,
      },
      method: "put",
      data: data,
    });
  }

  static resendOtp() {
    var pageURL = window.location.href;
    var lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);

    return Axios({
      url: "/user/resend/otp",
      method: "put",
      headers: {
        Authorization: "Bearer" + lastURLSegment,
      },
    });
  }

  static doctorRegister(data) {
    return axiosInstance({
      handlerEnabled: false,
      url: "/registration/provider/signup",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static forgotPassword(payload) {
    return axiosInstance({
      url: "/registration/forgot-password",
      method: "patch",
      data: payload,
    });
  }

  static resetPassword(data) {
    var pageURL = window.location.href;
    var lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);

    return Axios({
      url: `/public/resetPassword`,
      method: "patch",
      data: data,
      headers: {
        Authorization: "Bearer" + lastURLSegment,
      },
    });
  }

  static reverifyUser(data) {
    return Axios({
      url: "/registration/resend-otp",
      method: "patch",
      data: data,
    });
  }

  static reverifyUserEmail(data) {
    return Axios({
      url: "/registration/resend-email-verification",
      method: "patch",
      data: data,
    });
  }

  static CorporateRegister(data) {
    return axiosInstance({
      handlerEnabled: false,
      url: "registration/company/signup",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static CompanySignupDetails() {
    return axiosInstance({
      url: "public/company/companysignupdetails",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getAllCategories() {
    return axiosInstance({
      url: "public/categories/allcategories",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getCountries() {
    return axiosInstance({
      url: "/public/available-country",
      method: "get",
    });
  }

  static getStates(countryCode) {
    return axiosInstance({
      url: "/public/state",
      params: { countryCode: countryCode },
      method: "get",
    });
  }

  static getAvailableCities(countryCode, stateCode) {
    return axiosInstance({
      handlerEnabled: false,
      url: "/public/available-cities",
      method: "get",
      params: { countryCode: countryCode, stateCode: stateCode },
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static updatePatientProfile(data) {
    return axiosInstance({
      url: "/user/patient/profile",
      method: "patch",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static uploadPatientFile(payload) {
    return axiosInstance({
      url: `/user/patient/upload/file`,
      method: "put",
      data: payload,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }

  static uploadDoctorFile(payload) {
    return axiosInstance({
      url: `/user/provider/upload/file`,
      method: "put",
      data: payload,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }

  static getSpecialisationList() {
    return axiosInstance({
      handlerEnabled: true,
      url: "/user/provider/specialization",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getServicesList() {
    return axiosInstance({
      handlerEnabled: true,
      url: "/user/provider/services",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getDoctorWorkProfile() {
    return axiosInstance({
      url: "/user/provider/work-profile",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static updateDoctorBasicInfo(data) {
    return axiosInstance({
      url: "/user/provider/profile",
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static updateDoctorExtraInfo(data) {
    return axiosInstance({
      url: "/user/provider/extra-information",
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static updateDoctorWorkProfileInfo(data) {
    return axiosInstance({
      url: "/user/provider/work-profile",
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static getUserProfile() {
    return axiosInstance({
      handlerEnabled: true,
      url: "/user/profile",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getUserSubscriptionDetails(companyId) {
    return axiosInstance({
      handlerEnabled: true,
      url: `patient/${companyId}/subscriptiondetails`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getDoctorDetails(id) {
    return axiosInstance({
      url: `/user/provider/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getDoctorsList(data) {
    return axiosInstance({
      url: `/user/provider/list`,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  // static getCompanySelected(data) {
  //   return axiosInstance({
  //     url: `/user/provider/list`,
  //     method: "patch",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   });
  // }

  static getEmployeeList(companyId, employeeStatus, page) {
    return axiosInstance({
      url: `companies/${companyId}/employeeList/${employeeStatus}?page=${page}&limit=10`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static CompanyEmployeeInvite(data) {
    return axiosInstance({
      url: `companies/employee/invite`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
  }

  static getDoctorList(params) {
    return axiosInstance({
      url: `companies/providers/all`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      params,
    });
  }

  static getCompanySelectedProviders(companyId) {
    return axiosInstance({
      url: `companies/${companyId}/providers/companyselectedProviders`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getCompanyDoctors(companyId) {
    return axiosInstance({
      url: `companies/employees/${companyId}/companyselectedproviders`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getDoctorAvailability(providerId, date) {
    return axiosInstance({
      url: `/user/available-time-slot/${providerId}?date=${date}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static checkScheduleAvailable(data) {
    return axiosInstance({
      url: `/patient/schedule-details`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static getScheduleDetails(scheduleId) {
    return axiosInstance({
      url: `/patient/schedule-details/${scheduleId}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static updateScheduleDetails(data) {
    return axiosInstance({
      url: `/patient/schedule-details`,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static addZeroConsultationCharges(payload) {
    return axiosInstance({
      url: `payment/patient/zeroconsultationcharges/configure-payment`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    });
  }

  static fetchAllCompaniesPaymentDetails(params) {
    return axiosInstance({
      url: "companies/payment-details",
      method: "get",
      params: params,
    });
  }

  static buyPatientConsultation(companyId, patientId, data) {
    return axiosInstance({
      url: `companies/${companyId}/patient/${patientId}/buypatientconsultation`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
  }

  static deleteScheduleDetails(params) {
    return axiosInstance({
      url: `/patient/schedule-details`,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
    });
  }

  static blockEmployee(companyId, employeeId) {
    return axiosInstance({
      url: `companies/${companyId}/employee/${employeeId}/blockemployee`,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static unBlockEmployee(companyId, employeeId) {
    return axiosInstance({
      url: `companies/${companyId}/employee/${employeeId}/unblockemployee`,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getSubscriptionPlans() {
    return axiosInstance({
      url: `companies/subscriptions/allplans`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getCompanyProfile() {
    return axiosInstance({
      url: `companies/mycompanyprofile`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getBuySubscriptionPlans(companyId, subscriptionId, data) {
    return axiosInstance({
      url: `companies/${companyId}/subscriptions/${subscriptionId}/buy`,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
  }

  static updateSubscriptionPlans(companyId, subscriptionId, data) {
    return axiosInstance({
      url: `companies/${companyId}/subscriptions/${subscriptionId}/updatesubscription`,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
  }

  static fetchDoctorPriceDetails(providerId) {
    let isCorporatePatient = storage.user.getItem()?.isCorporatePatient;
    let url = isCorporatePatient
      ? `/companies/providers/${providerId}/providerPriceDetail`
      : `/patient/doctor-price-details/${providerId}`;
    return axiosInstance({
      url,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getAppointmentsPatient(params) {
    return axiosInstance({
      url: `/patient/all-appointments`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
    });
  }

  static getAppointmentDetailsPatient(scheduleId) {
    return axiosInstance({
      url: `/patient/appointment/${scheduleId}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getRechargeCredit(companyId, data) {
    return axiosInstance({
      url: `companies/${companyId}/credits/buycredits`,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
  }

  static editAppointmentTime(data) {
    return axiosInstance({
      url: `/patient/consultation-time`,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }

  static editProfileDetails(data) {
    return axiosInstance({
      url: `companies/mycompanyprofile/update`,
      method: "patch",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
  }

  static getEmployeeDetails(employeeId) {
    return axiosInstance({
      url: `companies/employeeList/${employeeId}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static editProfileLogo(companyId, formData) {
    return axiosInstance({
      url: `companies/${companyId}/upload/file`,
      method: "patch",
      headers: {
        type: "companyLogo",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  }

  static getAppointmentsDoctor(params) {
    return axiosInstance({
      url: `/provider/all-appointments`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
    });
  }

  static getAppointmentDetailsDoctor(scheduleId) {
    return axiosInstance({
      url: `/provider/appointment/${scheduleId}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getLanguages() {
    return axiosInstance({
      url: "/public/languages",
      method: "get",
    });
  }

  static getRelationships() {
    return axiosInstance({
      url: "/public/relationships",
      method: "get",
    });
  }

  static addSelectedDoctors(companyId, providerId) {
    return axiosInstance({
      url: `companies/${companyId}/providers/${providerId}/addprovidertocompany`,
      method: "patch",
    });
  }

  static deleteSelectedDoctors(companyId, providerId) {
    return axiosInstance({
      url: `companies/${companyId}/providers/${providerId}/deleteproviderfromcompany`,
      method: "patch",
    });
  }

  static uploadPatientChatFile(payload) {
    return axiosInstance({
      url: `/patient/upload-file`,
      method: "post",
      data: payload,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }

  static uploadDoctorChatFile(payload) {
    return axiosInstance({
      url: `/provider/upload-file`,
      method: "post",
      data: payload,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }

  static getDoctorChatHistory(params) {
    return axiosInstance({
      url: `/provider/fetch-Chat-History`,
      method: "get",
      params: params,
    });
  }

  static getPatientChatHistory(params) {
    return axiosInstance({
      url: `/patient/fetch-Chat-History`,
      method: "get",
      params: params,
    });
  }

  static getPatientIndividualChat(userId, params) {
    return axiosInstance({
      url: `/patient/fetch-Chat-History/${userId}`,
      method: "get",
      params: params,
    });
  }

  static getDoctorIndividualChat(userId, params) {
    return axiosInstance({
      url: `/provider/fetch-Chat-History/${userId}`,
      method: "get",
      params: params,
    });
  }

  static configurePayment(payload) {
    return axiosInstance({
      url: `/payment/patient/razorpay/configure-payment`,
      method: "post",
      data: payload,
    });
  }

  static configureStripePayment(payload) {
    return axiosInstance({
      url: `/payment/patient/stripe/configure-payment`,
      method: "post",
      data: payload,
    });
  }

  static applyCoupon(payload) {
    return axiosInstance({
      url: `/payment/patient/coupon/apply`,
      method: "post",
      data: payload,
    });
  }

  static getPatientDashboard() {
    return axiosInstance({
      url: `/patient/dashboard-details`,
      method: "get",
    });
  }

  static getSymptomCheckerToken() {
    return axiosInstance({
      url: `/patient/symptom-checker/token`,
      method: "get",
    });
  }

  static getAllSymptoms(params) {
    return axiosInstance({
      url: `/patient/symptom-checker/symptoms`,
      method: "get",
      params: params,
    });
  }

  static getAllProposedSymptoms(params) {
    return axiosInstance({
      url: `/patient/symptom-checker/proposed/symptoms`,
      method: "get",
      params: params,
    });
  }

  static getSymptomResult(params) {
    return axiosInstance({
      url: `/patient/symptom-checker/diagnosis`,
      method: "get",
      params: params,
    });
  }

  static getPatientUploadFileId(payload) {
    return axiosInstance({
      url: `/patient/create-file`,
      method: "post",
      data: payload,
    });
  }

  static getDoctortUploadFileId(payload) {
    return axiosInstance({
      url: `/provider/create-file`,
      method: "post",
      data: payload,
    });
  }

  static getAllConsultedDoctors(params) {
    return axiosInstance({
      url: `/patient/list-all-doctor-consulted`,
      method: "get",
      params: params,
    });
  }

  static verifyRazorPayPayment(id) {
    return axiosInstance({
      url: `/payment/razorpay/${id}`,
      method: "get",
    });
  }

  static verifyStripePayment(id) {
    return axiosInstance({
      url: `/payment/stripe/${id}`,
      method: "get",
    });
  }

  static verifyPayment(id) {
    return axiosInstance({
      url: `/payment/razorpay/${id}`,
      method: "get",
    });
  }

  static createStripePaymentIntent(data) {
    return axiosInstance({
      url: `payment/patient/createpaymentintent`,
      method: "post",
      data: data,
    });
  }

  static getDoctorEarnings() {
    return axiosInstance({
      url: `/provider/earnings`,
      method: "get",
    });
  }

  static getPaymentDetails(params) {
    return axiosInstance({
      url: `/provider/payment-details`,
      method: "get",
      params: params,
    });
  }

  static getDoctorDashboard() {
    return axiosInstance({
      url: `/provider/dashboard-details`,
      method: "get",
    });
  }

  static giveDoctorRatings(data) {
    return axiosInstance({
      url: `/patient/rating`,
      method: "post",
      data: data,
    });
  }

  static editPatientProfile(data) {
    return axiosInstance({
      url: "/user/profile",
      method: "patch",
      data: data,
    });
  }

  static clearUpcomingAppointments() {
    return axiosInstance({
      url: "/patient/upcoming-appointments",
      method: "patch",
    });
  }

  static getAllCities(params) {
    return axiosInstance({
      url: "/public/all-cities",
      method: "get",
      params: params,
    });
  }

  static getPatientPaymentHistory(params) {
    return axiosInstance({
      url: "/patient/payment-details",
      method: "get",
      params: params,
    });
  }

  static getAllPatientsConsulted(params) {
    return axiosInstance({
      url: `/provider/list-all-patient-consulted`,
      method: "get",
      params: params,
    });
  }

  static getAllReviews(params) {
    return axiosInstance({
      url: `/user/provider-reviews`,
      method: "get",
      params: params,
    });
  }

  static getCancellationReasons() {
    return axiosInstance({
      url: "/public/cancellation-reasons",
      method: "get",
    });
  }

  static cancelAppointmentPatient(data) {
    return axiosInstance({
      url: "/patient/cancel-appointment",
      method: "patch",
      data: data,
    });
  }

  static cancelAppointmentDoctor(data) {
    return axiosInstance({
      url: "/provider/cancel-appointment",
      method: "patch",
      data: data,
    });
  }

  static getHealthRecords(params) {
    return axiosInstance({
      url: "/user/patient/upload/medical-record",
      method: "get",
      params: params,
    });
  }

  static uploadHealthRecord(payload) {
    return axiosInstance({
      url: "/user/patient/upload/medical-record",
      method: "post",
      data: payload,
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }

  static deleteHealthRecord(params) {
    return axiosInstance({
      url: "/user/patient/upload/medical-record",
      method: "delete",
      params: params,
    });
  }

  static fetchFamilyMembers(params) {
    return axiosInstance({
      url: "/patient/family-members",
      method: "get",
      params: params,
    });
  }

  static addFamilyMember(payload) {
    return axiosInstance({
      url: "/patient/family-members",
      method: "patch",
      data: payload,
    });
  }

  static editFamilyMember(payload) {
    return axiosInstance({
      url: "/patient/family-members",
      method: "patch",
      data: payload,
    });
  }

  static deleteFamilyMember(params) {
    return axiosInstance({
      url: "/patient/family-members",
      method: "delete",
      params: params,
    });
  }

  static fetchAllNotificationsPatient(params) {
    return axiosInstance({
      url: "/patient/list-notification",
      method: "get",
      params: params,
    });
  }

  static clearAllNotificationsPatient() {
    return axiosInstance({
      url: "/patient/notification/clear-all",
      method: "patch",
    });
  }

  static markAsReadNotificationPatient(data) {
    return axiosInstance({
      url: "/patient/notification/mark-as-read",
      method: "patch",
      data: data,
    });
  }

  static fetchAllNotificationsProvider(params) {
    return axiosInstance({
      url: "/provider/list-notification",
      method: "get",
      params: params,
    });
  }

  static clearAllNotificationsProvider() {
    return axiosInstance({
      url: "/provider/notification/clear-all",
      method: "patch",
    });
  }

  static markAsReadNotificationProvider(data) {
    return axiosInstance({
      url: "/provider/notification/mark-as-read",
      method: "patch",
      data: data,
    });
  }

  static fetchAllNotificationsCompanies(params) {
    return axiosInstance({
      url: "/companies/notification/list-all",
      method: "get",
      params: params,
    });
  }

  static clearAllNotificationsCompanies() {
    return axiosInstance({
      url: "/companies/notification/clear-all",
      method: "patch",
    });
  }

  static markAsReadNotificationCompanies(data) {
    return axiosInstance({
      url: "/companies/notification/mark-as-read",
      method: "patch",
      data: data,
    });
  }

  static fetchAllMedicineTypes() {
    return axiosInstance({
      url: "/user/medicine-types",
      method: "get",
    });
  }

  static fetchAllMedicines(params) {
    return axiosInstance({
      url: "/user/medicine-library",
      method: "get",
      params: params,
    });
  }

  static fetchMedication(params) {
    return axiosInstance({
      url: "/patient/list-medications",
      method: "get",
      params: params,
    });
  }

  static addMedicine(data) {
    return axiosInstance({
      url: "/user/medication",
      method: "post",
      data: data,
    });
  }

  static createPrescription(data) {
    return axiosInstance({
      url: "/provider/prescription",
      method: "post",
      data: data,
    });
  }

  static fetchPrescriptionDetails(id) {
    return axiosInstance({
      url: `/provider/prescription/${id}`,
      method: "get",
    });
  }

  static changePassword(data) {
    return axiosInstance({
      url: "/user/account/reset-password",
      method: "patch",
      data: data,
    });
  }

  static deleteAccount() {
    return axiosInstance({
      url: "/user/account/delete-profile",
      method: "patch",
    });
  }

  static getTimezones() {
    return axiosInstance({
      url: "/user/available-timezone",
      method: "get",
    });
  }

  static getAccountSettingsInfo() {
    return axiosInstance({
      url: "/user/account/status",
      method: "get",
    });
  }

  static changeTimeZone(data) {
    return axiosInstance({
      url: "/user/account/timezone",
      method: "patch",
      data: data,
    });
  }

  static changeEmailNotification(data) {
    return axiosInstance({
      url: "/user/email-notification/switch",
      method: "patch",
      data: data,
    });
  }

  static changeNotificationStatus(data) {
    return axiosInstance({
      url: "/user/notification/switch",
      method: "patch",
      data: data,
    });
  }

  static changeMedicationReminder(data) {
    return axiosInstance({
      url: "/patient/reminder-settings",
      method: "patch",
      data: data,
    });
  }

  static checkFollowUpAvailable(params) {
    return axiosInstance({
      url: "/patient/follow-up",
      method: "get",
      params: params,
    });
  }

  static getConsultedDoctors(params) {
    return axiosInstance({
      url: "/patient/all-consulted-doctors",
      method: "get",
      params: params,
    });
  }

  static getConsultedPatients(params) {
    return axiosInstance({
      url: "/provider/consulted-patients",
      method: "get",
      params: params,
    });
  }

  static getPatientsDetails(params) {
    return axiosInstance({
      url: "/provider/consulation-details",
      method: "get",
      params: params,
    });
  }

  static reportIssues(data) {
    return axiosInstance({
      url: "/patient/report-issues",
      method: "post",
      data: data,
    });
  }

  static editMedicineProvider(data) {
    return axiosInstance({
      url: "/provider/medication",
      method: "patch",
      data: data,
    });
  }

  static fetchPrivacyPolicy() {
    return axiosInstance({
      url: "/public/privacy-policy",
      method: "get",
    });
  }

  static fetchConfidentialityAgreement() {
    return axiosInstance({
      url: "/public/confidentiality-agreement",
      method: "get",
    });
  }

  static addCalenderSync(data) {
    return axiosInstance({
      url: "/provider/appointments/calendar-sync",
      method: "post",
      data,
    });
  }

  static removeCalenderSync() {
    return axiosInstance({
      url: "/provider/appointments/remove-calendar-sync",
      method: "patch",
    });
  }

  static addNewMedicine(payload) {
    return axiosInstance({
      url: "/admin/medicine",
      method: "post",
      data: payload,
    });
  }

  static deletePrescriptionMedicine(id) {
    return axiosInstance({
      url: `/provider/medication/${id}`,
      method: "delete",
    });
  }

  static checkAppointmentFlow(id) {
    return axiosInstance({
      url: `/patient/show-followUp/${id}`,
      method: "get",
    });
  }

  static fetchCurrencies(params) {
    return axiosInstance({
      url: `/user/currency-codes?searchKey=`,
      method: "get",
      params: params,
    });
  }

  static getAllLanguages(params) {
    return axiosInstance({
      url: "/user/languages",
      method: "get",
      params: params,
    });
  }

  // Survey Form Api end points

  static getAllSurveyFormList(data) {
    return axiosInstance({
      url: "/patient/forms/admincreated/allforms",
      method: "get",
      data,
    });
  }

  static getQuestionList(formId) {
    return axiosInstance({
      url: `/patient/forms/admincreated/${formId}`,
      method: "get",
    });
  }

  static getSubmittedAnswerForSingleForm(formId) {
    return axiosInstance({
      url: `/patient/forms/${formId}/answers`,
      method: "get",
    });
  }

  static submitAnswer(data) {
    return axiosInstance({
      url: `/patient/forms/submit-answer`,
      method: "post",
      data,
    });
  }

  static getArticles() {
    return axiosInstance({
      url: "https://teal-oryx-273145.hostingersite.com/wp-json/wp/v2/posts",
      method: "get",
    });
  }

  static sendLoginOtp(data) {
    return axiosInstance({
      url: `/registration/user/login/send-verification`,
      method: "post",
      data,
    });
  }

  static verifyLoginOtp(data) {
    return axiosInstance({
      url: `/registration/patient/login/otp`,
      method: "post",
      data,
    });
  }

  static verifyProviderLoginOtp(data) {
    return axiosInstance({
      url: `/registration/provider/login/otp`,
      method: "post",
      data,
    });
  }

  static sendSignUpOtp(data) {
    return axiosInstance({
      url: `/registration/user/signup/send-verification`,
      method: "post",
      data,
    });
  }

  static verifyAndRegisterPatient(data) {
    return axiosInstance({
      url: `/registration/patient/signup/check-verification`,
      method: "post",
      data,
    });
  }

  static verifyAndRegisterProvider(data) {
    return axiosInstance({
      url: `/registration/provider/signup/check-verification`,
      method: "post",
      data,
    });
  }

  static updatePatientProfile(payload) {
    return axiosInstance({
      url: `/registration/patient/signup`,
      method: "put",
      data: payload,
    });
  }

  static verifyCaptcha(data) {
    return axiosInstance({
      url: `/registration/verify-captcha`,
      method: "post",
      data,
    });
  }

  static sendForgotPasswordVerification(data) {
    return axiosInstance({
      url: `/registration/forgot-password/send-verification`,
      method: "post",
      data,
    });
  }

  static verifyForgotPasswordCode(data) {
    return axiosInstance({
      url: `/registration/forgot-password/check-verification`,
      method: "post",
      data,
    });
  }

  static googleAuth(data) {
    return axiosInstance({
      url: `/registration/patient/auth/google`,
      method: "post",
      data,
    });
  }

  static googleProviderAuth(data) {
    return axiosInstance({
      url: `/registration/provider/auth/google`,
      method: "post",
      data,
    });
  }

  static fetchBannerData() {
    return axiosInstance({
      url: `/admin/get-banner-content`,
      method: "GET",
    });
  }

  static updateAllReminder(data) {
    return axiosInstance({
      url: `/patient/reminder-settings/turn-off-all-reminders`,
      method: "PATCH",
      data,
    });
  }

  static extendAppointment(data) {
    return axiosInstance({
      url: `/provider/appointments/extend`,
      method: "patch",
      data,
    });
  }

  static sendGooglePhoneVerification(data) {
    return axiosInstance({
      url: `/registration/user/google/send-verification`,
      method: "post",
      data,
    });
  }

  static verifyGoogleOtp(data) {
    return axiosInstance({
      url: `/registration/user/google/check-verification`,
      method: "post",
      data,
    });
  }
}
