import React from "react";
import {
  CardWrapper,
  ImageContainer,
  CardImage,
  ContentContainer,
  Title,
  Description,
} from "./styledComponent";
import CustomTimeChip from "../CustomTimeChip";
import CustomArticleButton from "../CustomArticleButton";

export function convertImg(htmlContent) {
  const regex = /<img[^>]*src="(.*?)"/;
  const match = regex.exec(htmlContent);
  if (match) {
    return match[1];
  }
  return null;
}
const stripHtml = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
};
const ArticleCard = ({ data, onButtonClick, buttonText, className }) => {
  return (
    <CardWrapper className={className}>
      <ImageContainer className="Article-ImageContainer">
        <CardImage
          src={convertImg(data.content.rendered)}
          alt={data.title?.rendered || "Article image"}
        />
      </ImageContainer>
      <ContentContainer>
        <div>
          <CustomTimeChip
            time={data.readTime ? data.readTime + " min read" : "4 min read"}
          />
          <Title className="Article-Title">{data.title?.rendered || ""}</Title>
          <Description className="Article-Description">
            {stripHtml(data.excerpt?.rendered) || ""}
          </Description>
        </div>
        <CustomArticleButton
          onClick={() => onButtonClick(data)}
          data={data}
          buttonText={buttonText}
        />
      </ContentContainer>
    </CardWrapper>
  );
};

export default ArticleCard;
