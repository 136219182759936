import * as React from "react";

function LogoutIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9994 1.79856C6.36589 1.79856 1.79905 6.3654 1.79905 11.9989C1.79905 17.6324 6.36589 22.1992 11.9994 22.1992C17.6329 22.1992 22.1997 17.6324 22.1997 11.9989C22.1997 6.3654 17.6329 1.79856 11.9994 1.79856ZM0.074707 11.9989C0.074707 5.41308 5.41356 0.0742188 11.9994 0.0742188C18.5852 0.0742188 23.924 5.41308 23.924 11.9989C23.924 18.5847 18.5852 23.9236 11.9994 23.9236C5.41356 23.9236 0.074707 18.5847 0.074707 11.9989ZM14.4393 4.78506C14.6281 4.94882 14.7365 5.18645 14.7365 5.43639V8.24889C14.7365 8.72505 14.3505 9.11106 13.8744 9.11106C13.3982 9.11106 13.0122 8.72505 13.0122 8.24889V6.43048L8.17405 7.12165V16.8761L13.0122 17.5673V15.7489C13.0122 15.2727 13.3982 14.8867 13.8744 14.8867C14.3505 14.8867 14.7365 15.2727 14.7365 15.7489V18.5614C14.7365 18.8113 14.6281 19.049 14.4393 19.2127C14.2505 19.3765 13.9999 19.4502 13.7524 19.4149L7.18995 18.4774C6.7652 18.4167 6.44971 18.0529 6.44971 17.6239V6.37389C6.44971 5.94483 6.7652 5.58106 7.18995 5.52038L13.7524 4.58288C13.9999 4.54754 14.2505 4.6213 14.4393 4.78506ZM16.0772 9.51424C16.4139 9.17754 16.9598 9.17754 17.2965 9.51424L19.1715 11.3892C19.5082 11.7259 19.5082 12.2718 19.1715 12.6085L17.2965 14.4835C16.9598 14.8202 16.4139 14.8202 16.0772 14.4835C15.7405 14.1468 15.7405 13.6009 16.0772 13.2642L16.4804 12.8611H11.0619C10.5857 12.8611 10.1997 12.4751 10.1997 11.9989C10.1997 11.5227 10.5857 11.1367 11.0619 11.1367H16.4804L16.0772 10.7335C15.7405 10.3968 15.7405 9.85094 16.0772 9.51424Z"
        fill="#C24646"
      />
    </svg>
  );
}

export default LogoutIcon;
