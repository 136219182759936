import React from "react";
import BackButton from "../BackButton";
import { PageTitle, TitleWrapper } from "./styledComponent";

const BackButtonWithTitle = ({ onClick, title, isButtonRequired }) => {
  return (
    <TitleWrapper className="TitleWrapper">
      {isButtonRequired ? (
        <BackButton onClick={onClick} className="Back-Button-Style" />
      ) : (
        ""
      )}{" "}
      <PageTitle className="PageTitle">{title}</PageTitle>
    </TitleWrapper>
  );
};

export default BackButtonWithTitle;
