import React from "react";
import { MenuItem, MenuText, IconTextWrapper } from "./styledComponent";

const MenuItemWithIcon = ({
  buttonText,
  onMenuClick,
  className,
  width,
  height,
  chevronIconColor,
  svg,
  src,
  isChevronIcon,
  textColor,
  activeKey,
  children,
}) => {
  return (
    <MenuItem onClick={onMenuClick} className={className} activeKey={activeKey}>
      <IconTextWrapper className="IconTextWrapper">
        {children ? (
          children
        ) : (
          <>
            {svg ? svg : <img src={src} />}
            <MenuText
              className="MenuText"
              textColor={textColor}
              activeKey={activeKey}
            >
              {buttonText}
            </MenuText>
          </>
        )}
      </IconTextWrapper>
      {isChevronIcon ? (
        <svg
          width={width ? width : "8"}
          height={height ? height : "14"}
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
            fill={
              chevronIconColor
                ? chevronIconColor
                : textColor
                ? textColor
                : "#000000"
            }
          />
        </svg>
      ) : (
        ""
      )}
    </MenuItem>
  );
};

export default MenuItemWithIcon;
