import styled from "styled-components";
export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  gap: 15px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
  color: ${(props) => (props.activeKey ? "#1a1a1a" : "#ffffff")};
  border: 1px solid ${(props) => (props.activeKey ? "#e3e9f9" : "transparent")};
  background-color: ${(props) => (props.activeKey ? "#e3e9f9" : "transparent")};
`;
export const MenuText = styled.span`
  font-size: 14px;
  flex: 1;
  color: ${(props) =>
    props.activeKey
      ? "rgba(55, 63, 106, 1)"
      : props.textColor
      ? props.textColor
      : "#ffffff"};
  font-weight: 700;
  font-family: "Schibsted Grotesk";
  line-height: 17.28px;
  letter-spacing: -0.02em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12.5px;
  height: 100%;
  width: calc(100% - 8px);
  justify-content: start;
`;
