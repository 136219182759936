import React, { useState, useEffect } from "react";
import {
  BlogsPageWrapper,
  BlogCard,
  Name,
  InfoContainer,
  MoreInfo,
  ListContainer,
  PageTitle,
  ShowIcon,
  EmptyResultMessageRecord,
  ArticleFiltersContainer,
} from "./styledComponents";
import axios from "axios";
import { LoadingOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";

const blogs = [
  {
    id: 1,
    title: { rendered: "Sample Blog Post 1" },
    content: { rendered: "<p>This is a sample blog post.</p>" },
    date: "2024-07-15",
    categories: [1, 2],
  },
  {
    id: 2,
    title: { rendered: "Sample Blog Post 2" },
    content: { rendered: "<p>Another sample blog post.</p>" },
    date: "2024-07-10",
    categories: [2, 3],
  },
  {
    id: 3,
    title: { rendered: "Sample Blog Post 3" },
    content: { rendered: "<p>Yet another sample blog post.</p>" },
    date: "2024-07-05",
    categories: [1],
  },
  {
    id: 4,
    title: { rendered: "Sample Blog Post 4" },
    content: { rendered: "<p>This is the fourth sample blog post.</p>" },
    date: "2024-07-03",
    categories: [3],
  },
  {
    id: 5,
    title: { rendered: "Sample Blog Post 5" },
    content: { rendered: "<p>Another blog post content.</p>" },
    date: "2024-06-28",
    categories: [1, 2],
  },
  {
    id: 6,
    title: { rendered: "Sample Blog Post 6" },
    content: { rendered: "<p>Sample content for blog post 6.</p>" },
    date: "2024-06-25",
    categories: [2],
  },
  {
    id: 7,
    title: { rendered: "Sample Blog Post 7" },
    content: { rendered: "<p>Content for blog post 7.</p>" },
    date: "2024-06-20",
    categories: [1, 3],
  },
  {
    id: 8,
    title: { rendered: "Sample Blog Post 8" },
    content: { rendered: "<p>Another sample blog post content.</p>" },
    date: "2024-06-15",
    categories: [1, 3],
  },
  {
    id: 9,
    title: { rendered: "Sample Blog Post 9" },
    content: { rendered: "<p>Sample content for blog post 9.</p>" },
    date: "2024-06-10",
    categories: [2, 4],
  },
  {
    id: 10,
    title: { rendered: "Sample Blog Post 10" },
    content: { rendered: "<p>Content for blog post 10.</p>" },
    date: "2024-06-05",
    categories: [5],
  },
  {
    id: 11,
    title: { rendered: "Sample Blog Post 11" },
    content: { rendered: "<p>Another sample blog post.</p>" },
    date: "2024-06-01",
    categories: [6],
  },
  {
    id: 12,
    title: { rendered: "Sample Blog Post 12" },
    content: { rendered: "<p>Sample content for blog post 12.</p>" },
    date: "2024-05-28",
    categories: [1, 2, 7, 8],
  },
  {
    id: 13,
    title: { rendered: "Sample Blog Post 13" },
    content: { rendered: "<p>Content for blog post 13.</p>" },
    date: "2024-05-25",
    categories: [1, 7],
  },
  {
    id: 14,
    title: { rendered: "Sample Blog Post 14" },
    content: { rendered: "<p>Another sample blog post content.</p>" },
    date: "2024-05-20",
    categories: [8],
  },
  {
    id: 15,
    title: { rendered: "Sample Blog Post 15" },
    content: { rendered: "<p>Sample content for blog post 15.</p>" },
    date: "2024-05-15",
    categories: [9],
  },
  {
    id: 16,
    title: { rendered: "Sample Blog Post 16" },
    content: { rendered: "<p>Content for blog post 16.</p>" },
    date: "2024-05-10",
    categories: [10, 9],
  },
  {
    id: 17,
    title: { rendered: "Sample Blog Post 17" },
    content: { rendered: "<p>Another sample blog post.</p>" },
    date: "2024-05-05",
    categories: [11, 10],
  },
  {
    id: 18,
    title: { rendered: "Sample Blog Post 18" },
    content: { rendered: "<p>Sample content for blog post 18.</p>" },
    date: "2024-04-30",
    categories: [11],
  },
  {
    id: 19,
    title: { rendered: "Sample Blog Post 19" },
    content: { rendered: "<p>Content for blog post 19.</p>" },
    date: "2024-04-25",
    categories: [1, 12],
  },
  {
    id: 20,
    title: { rendered: "Sample Blog Post 20" },
    content: { rendered: "<p>Another sample blog post content.</p>" },
    date: "2024-04-20",
    categories: [12],
  },
];

const filters = [
  "All",
  "Mental Health",
  "OCD",
  "BPD",
  "CBT",
  "Self Care",
  "Anxiety Disorder",
  "Depression",
  "Methamphetamine",
  "Trauma",
  "Therapy",
  "Stress Management",
  "Mental Health and technologies",
  "Mental Health and social media impact",
];

const Blogs = () => {
  const history = useHistory();
  const [blogsData, setBlogsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [filterData, setFilterData] = useState(0);
  const initialDataToShow = 5;
  useEffect(() => {
    fetchBlogs();
  }, []);
  const fetchBlogs = () => {
    setLoading(true);
    axios
      .get("https://teal-oryx-273145.hostingersite.com/wp-json/wp/v2/posts")
      .then(function (response) {
        setBlogsData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.error("Error fetching data: ", error);
      });
  };

  function convertImg(htmlContent) {
    const regex = /<img[^>]*src="(.*?)"/;
    const match = regex.exec(htmlContent);
    if (match) {
      return match[1];
    }
    return null;
  }

  const goToBlogPage = (item) => {
    window.open(`https://telehealings.com/blog/${item.slug}`, "_blank");
    // history.push(`/user/base/home/articles-blogs/${item.slug}`);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleFilterOption = (e) => {
    setFilterData(e);
  };

  return (
    <>
      <BlogsPageWrapper>
        <PageTitle
          style={{ fontWeight: "900", fontFamily: "Metropolis Black" }}
        >
          Articles
        </PageTitle>
        <ArticleFiltersContainer>
          {filters.map((item, index) => (
            <div
              key={index}
              className={`filterItemContainer ${
                index === filterData ? "selected" : ""
              }`}
              onClick={() => handleFilterOption(index)}
            >
              <span className="filterItem">{item}</span>
            </div>
          ))}
        </ArticleFiltersContainer>
        <div style={{ borderBottom: "1px solid #E3E3E3" }}></div>
        <ListContainer>
          {/* {loading ? (
                        <LoadingOutlined />
                    ) : blogsData && blogsData.length > 0 ? (
                        <>
                            {blogsData.slice(0, showAll ? blogsData.length : initialDataToShow).map((data, index) => (
                                filterData !== 0 && data.categories.includes(filterData) &&(
                                <BlogCard key={index} onClick={() => goToBlogPage(data)}>
                                    <img src={convertImg(data.content.rendered)} style={{ height: '80px', width: '80px', borderRadius: '5px' }} alt="Blog post image" />
                                    <InfoContainer>
                                        <Name title={data.title.rendered} style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Metropolis Regular' }}>
                                            {data.title.rendered.length > 50
                                                ? `${data.title.rendered.slice(0, 50)}...`
                                                : data.title.rendered}
                                        </Name>
                                        <MoreInfo style={{ fontWeight: '700', fontSize: '11px', fontFamily: 'Metropolis Regular' }}>{`${moment(data.date).format("MMM D, YYYY")}`}</MoreInfo>
                                    </InfoContainer>
                                </BlogCard>)
                            ))}
                        </>
                    ) : (
                        <div>
                            <EmptyResultMessageRecord>No article found !!!</EmptyResultMessageRecord>
                        </div>
                    )} */}
          {loading ? (
            <LoadingOutlined />
          ) : (
            <>
              {blogsData && blogsData.length > 0 ? (
                blogsData
                  .filter(
                    (data) =>
                      filterData === 0 || data.categories.includes(filterData)
                  )
                  .slice(0, showAll ? blogsData.length : initialDataToShow)
                  .map((data, index) => (
                    <BlogCard key={index} onClick={() => goToBlogPage(data)}>
                      <img
                        src={convertImg(data.content.rendered)}
                        style={{
                          height: "80px",
                          width: "80px",
                          borderRadius: "5px",
                        }}
                        alt="Blog post image"
                      />
                      <InfoContainer>
                        <Name
                          title={data.title.rendered}
                          style={{
                            fontWeight: "700",
                            fontSize: "14px",
                            fontFamily: "Metropolis Regular",
                          }}
                        >
                          {data.title.rendered.length > 50
                            ? `${data.title.rendered.slice(0, 50)}...`
                            : data.title.rendered}
                        </Name>
                        <MoreInfo
                          style={{
                            fontWeight: "700",
                            fontSize: "11px",
                            fontFamily: "Metropolis Regular",
                          }}
                        >
                          {`${moment(data.date).format("MMM D, YYYY")}`}
                        </MoreInfo>
                      </InfoContainer>
                    </BlogCard>
                  ))
              ) : (
                <EmptyResultMessageRecord>
                  No articles found!!!
                </EmptyResultMessageRecord>
              )}
              {blogsData &&
                blogsData.length > 0 &&
                filterData !== 0 &&
                !blogsData.some((data) =>
                  data.categories.includes(filterData)
                ) && (
                  <EmptyResultMessageRecord>
                    No articles found under this category.
                  </EmptyResultMessageRecord>
                )}
            </>
          )}
        </ListContainer>
        {(filterData === 0 ||
          (filterData !== 0 &&
            blogsData &&
            blogsData.length > initialDataToShow &&
            initialDataToShow >= 5)) && (
          <ShowIcon onClick={toggleShowAll}>
            {showAll ? (
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  fontFamily: "Metropolis Regular",
                }}
              >
                <UpOutlined style={{ marginRight: "5px" }} /> View less
              </div>
            ) : (
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  fontFamily: "Metropolis Regular",
                }}
              >
                <DownOutlined style={{ marginRight: "5px" }} /> View more
              </div>
            )}
          </ShowIcon>
        )}
      </BlogsPageWrapper>
    </>
  );
};

export default Blogs;
