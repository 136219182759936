import React, { useState, useEffect } from "react";
import {
  BlogsPageWrapper,
  EmptyResultMessageRecord,
  CardGrid,
  ArticleFiltersContainer,
} from "./styledComponents";
import { useHistory } from "react-router-dom";
import CustomArticleChip from "../../components/CustomArticleChip";
import ArticleCard from "../../components/ArticleCard";
import BackButtonWithTitle from "../../components/BackButtonWithTitle";
import useBlogApi from "./useBlogApi";
import CustomLoader from "../../components/CustomLoader";

export const filters = [
  "All",
  "Mental Health",
  "OCD",
  "BPD",
  "CBT",
  "Self Care",
  "Anxiety Disorder",
  "Depression",
  "Methamphetamine",
  "Trauma",
  "Therapy",
  "Stress Management",
  "Mental Health and technologies",
  "Mental Health and social media impact",
];

const Blogs = () => {
  const { fetchBlogData, loading } = useBlogApi();
  const history = useHistory();
  const [blogsData, setBlogsData] = useState([]);
  const [filterData, setFilterData] = useState(0);
  const goToBlogPage = (item) => {
    history.push(`/user/base/home/articles-blogs/${item.slug}`);
  };

  const handleFilterOption = (e) => {
    setFilterData(e);
  };
  const postSuccessFunction = (res) => {
    setBlogsData(res);
  };
  useEffect(() => {
    fetchBlogData(postSuccessFunction);
  }, []);

  return (
    <>
      <BlogsPageWrapper>
        <BackButtonWithTitle title={"Articles"} />
        <ArticleFiltersContainer className="ArticleFiltersContainer">
          {filters.map((item, index) => (
            <CustomArticleChip
              text={item}
              className={index === filterData ? "selected" : ""}
              onClick={() => handleFilterOption(index)}
            />
          ))}
        </ArticleFiltersContainer>

        {loading ? (
          <CustomLoader />
        ) : (
          <CardGrid className="CardGrid">
            {blogsData && blogsData.length > 0 ? (
              blogsData
                .filter(
                  (data) =>
                    filterData === 0 || data.categories.includes(filterData)
                )
                .map((data, index) => (
                  <ArticleCard
                    key={index}
                    data={data}
                    onButtonClick={() => goToBlogPage(data)}
                    buttonText={"Read Now"}
                  />
                ))
            ) : (
              <EmptyResultMessageRecord>
                No articles found!!!
              </EmptyResultMessageRecord>
            )}
            {blogsData &&
              blogsData.length > 0 &&
              filterData !== 0 &&
              !blogsData.some((data) =>
                data.categories.includes(filterData)
              ) && (
                <EmptyResultMessageRecord>
                  No articles found under this category.
                </EmptyResultMessageRecord>
              )}
          </CardGrid>
        )}
      </BlogsPageWrapper>
    </>
  );
};

export default Blogs;
