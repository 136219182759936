import styled from "styled-components";

export const CardWrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  transition: box-shadow 0.3s ease;
  padding: 16px;
`;

export const ImageContainer = styled.div`
  height: 180px;
  width: 100%;
  margin-bottom: 32px;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

export const ContentContainer = styled.div`
  position: relative;
  padding: 32px 0 0 0;
  height: calc(100% - 212px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  .ReadTimeBadge {
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 12px 0;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #000000;
  font-family: "Schibsted Grotesk";
  cursor: default;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin: 0 0 16px 0;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: "Schibsted Grotesk";
  cursor: default;
`;


