import axios from "axios";
import { useState } from "react";
const useBlogApi = () => {
  const [loading, setLoading] = useState(false);
  const fetchBlogData = (postSuccessFunction) => {
    setLoading(true);
    axios
      .get("https://teal-oryx-273145.hostingersite.com/wp-json/wp/v2/posts")
      .then(function (response) {
        if (postSuccessFunction) {
          postSuccessFunction(response.data);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.error("Error fetching data: ", error);
      });
  };
  return {
    fetchBlogData,
    loading,
  };
};

export default useBlogApi;
