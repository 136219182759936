import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  EmptyResultMessageRecord,
  BlogContainer,
  InfoTitle,
  SubTitle,
  InfoDate,
  Content,
  CardImage,
  BreadCrumb,
  BlogsInfoPageWrapper,
  BlogContentContainer,
  RelatedContentContainer,
  AuthorName,
} from "./styledComponents";
import BackButtonWithTitle from "../../components/BackButtonWithTitle";
import ArticleCard, { convertImg } from "../../components/ArticleCard";
import { filters } from "./Blogs";
import CustomTimeChip from "../../components/CustomTimeChip";
import { useHistory } from "react-router-dom";
import CustomArticleButton from "../../components/CustomArticleButton";
import useBlogApi from "./useBlogApi";
import CustomLoader from "../../components/CustomLoader";

const BlogDetail = () => {
  const { fetchBlogData, loading } = useBlogApi();
  const { slug } = useParams();
  const history = useHistory();
  const [blogData, setBlogData] = useState(null);
  const [relatedDatas, setRelatedDatas] = useState(null);
  const filteredValues = filters.filter((_, index) =>
    blogData ? blogData?.categories?.includes(index) : ""
  );

  const goToBlogPage = (item) => {
    history.push(`/user/base/home/articles-blogs/${item.slug}`);
  };
  const handleButtonClick = () => {};
  const handleBackClick = () => {
    window.history.back();
  };
  const postSuccessFunction = (res) => {
    const selectedItem = res?.find((item) => item.slug === slug);
    setBlogData(selectedItem ?? {});
    // Filter out the selected item from the related data
    const relatedDatas = res?.filter((item) => item.slug !== slug);
    setRelatedDatas(relatedDatas);
  };
  useEffect(() => {
    fetchBlogData(postSuccessFunction);
  }, []);

  return (
    <BlogsInfoPageWrapper>
      <BackButtonWithTitle
        isButtonRequired
        onClick={handleBackClick}
        title={"Articles"}
      />
      <div>
        {loading ? (
          <CustomLoader />
        ) : blogData ? (
          <>
            <BreadCrumb className="BreadCrumb">
              <span onClick={handleBackClick}>Articles</span>
              <span> / </span>
              <span>{filteredValues}</span>
            </BreadCrumb>
            <BlogContainer className="BlogContainer">
              <BlogContentContainer className="BlogContentContainer">
                <CustomTimeChip
                  time={
                    blogData?.readTime
                      ? blogData?.readTime + " min read"
                      : "4 min read"
                  }
                />
                <InfoTitle className="InfoTitle-Large">
                  {blogData.title.rendered}
                </InfoTitle>
                <AuthorName className="author-style">
                  {blogData.author}
                </AuthorName>
                <CardImage
                  className="CardImage"
                  src={convertImg(blogData.content.rendered)}
                  alt={blogData.title?.rendered || "Article image"}
                />
                <div className="Content-Wrapper">
                  <InfoDate className="InfoDate">Jan 27, 2024</InfoDate>
                  <InfoTitle className="InfoTitle-Small">
                    {blogData.title.rendered}
                  </InfoTitle>
                  <Content className="Content-Style">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blogData?.excerpt?.rendered,
                      }}
                    />
                    <CustomArticleButton
                      buttonText={"Finish Exercise"}
                      onButtonClick={handleButtonClick}
                      className={"Finish-Button-Style"}
                    />
                  </Content>
                </div>
              </BlogContentContainer>
              <RelatedContentContainer className="RelatedContentContainer">
                <SubTitle>You May Also Like</SubTitle>
                <div className="Mapped-Card-Wrapper">
                  {relatedDatas.map((item, index) => (
                    <ArticleCard
                      data={item}
                      key={index}
                      onButtonClick={() => goToBlogPage(item)}
                      buttonText={"Read Now"}
                      className="Article-Card-Style"
                    />
                  ))}
                </div>
              </RelatedContentContainer>
            </BlogContainer>
          </>
        ) : (
          <div>
            <EmptyResultMessageRecord>
              No article found !!!
            </EmptyResultMessageRecord>
          </div>
        )}
      </div>
    </BlogsInfoPageWrapper>
  );
};

export default BlogDetail;
