import styled from "styled-components";
export const Chip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f8f8f8;
  border-radius: 128px;
  flex-basis: auto;
  white-space: nowrap;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 4px 15px;
  background-color: #f8f8f8;
  cursor: pointer;
  &.selected {
    font-size: 16px;
    font-weight: 700;
    font-family: Metropolis Bold;
    background-color: #425889;
    border-color: #425889;
    color: #ffffff;
    span {
      color: #ffffff;
    }
  }
`;

export const ChipText = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #666666;
  font-family: Metropolis Bold;
`;
