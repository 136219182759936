import React from "react";
import PhoneInput from "react-phone-input-2";

const PhoneForm = ({ phoneNumber, setPhoneNumber, setPhoneNumberFormat }) => {
  return (
    <PhoneInput
      style={{}}
      inputStyle={{
        border: "none",
        width: "100%",
        fontSize: "18px",
        fontWeight: "500",
        height: "55px",
        borderRadius: "4px",
        backgroundColor: "#FBFBFB",
      }}
      searchStyle={{ width: "100%", display: "flex" }}
      buttonStyle={{ border: "none", backgroundColor: "#FBFBFB" }}
      placeholder="Phone Number"
      inputProps={{
        name: "phoneNumber",
        required: true,
      }}
      value={phoneNumber}
      country="in"
      onChange={(val, val2) => {
        setPhoneNumberFormat(val2.dialCode);
        setPhoneNumber(val);
      }}
    />
  );
};

export default PhoneForm;
