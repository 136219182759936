import styled from "styled-components";

export const BlogsPageWrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 40px 144px 90px 80px;

  @media only screen and (max-width: 900px) {
    padding: 13px 20px 35px 20px;
    .TitleWrapper {
      margin-bottom: 20px !important;

      .PageTitle {
        font-size: 22px !important;
      }
    }

    .ArticleFiltersContainer {
      display: none;
    }
    .CardGrid {
      grid-template-columns: repeat(auto-fill, minmax(274px, 1fr)) !important;
      padding: 0px !important;
      .Article-ImageContainer {
        margin-bottom: 20px !important;
      }
      .ReadTimeBadge {
        font-size: 14px !important;
      }
      .Article-Title {
        -webkit-line-clamp: 1;
      }
      .Article-Description {
        font-size: 18px !important;
        -webkit-line-clamp: 2;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 13px 20px 35px 20px;
  }
  @media (min-width: 601px) and (max-width: 900px) {
    padding: 20px 40px 45px 20px !important;
  }
  @media (min-width: 901px) and (max-width: 1279px) {
    padding: 20px 60px 45px 50px !important;
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    padding: 30px 80px 65px 60px !important;
  }
`;
export const BlogsInfoPageWrapper = styled.div`
  height: auto;
  width: 90%;
  margin: 0 auto;
  padding: 48px 30px 20px 15px;
  .TitleWrapper {
    padding: 10px 0 40px;
  }
  .Finish-Button-Style {
    margin-top: 56px;
    width: 100%;
  }
  .ReadTimeBadge {
    margin: 0px 0px 36px;
    font-weight: 800;
  }
  .InfoTitle-Small {
    display: none;
  }
  .CardImage {
    border-radius: 8px;
  }
  .Content-Style {
    p {
      text-align: justify;
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 0 0 20px 0;
    width: 100%;
    margin: 0 !important;
    .TitleWrapper {
      padding: 10px 20px 36px;
    }
    .BlogContainer {
      padding: 0px;
      gap: 40px !important;
    }
    .BlogContentContainer {
      width: 100% !important;
      .CardImage {
        border-radius: 0px;
        max-height: 189px;
      }
      .Content-Wrapper {
        padding: 0 34px 0 20px;
        .InfoDate {
          padding: 24px 0 8px;
          font-size: 14px;
        }
        .InfoTitle-Small {
          display: block;
          font-size: 28px;
          line-height: 34.56px;
          margin-bottom: 32px;
        }
      }
    }
    .InfoTitle-Large,
    .author-style,
    .ReadTimeBadge,
    .Finish-Button-Style,
    .RelatedContentContainer,
    .BreadCrumb,
    .PageTitle {
      display: none;
    }
  }
  @media (min-width: 100px) and (max-width: 429px) {
    .TitleWrapper {
      padding: 10px 15px 30px !important;
    }
    .Content-Wrapper {
      padding: 0 28px 0 15px;
      .InfoDate {
        font-size: 12px !important;
      }
      .InfoTitle-Small {
        font-size: 24px !important;
      }
    }
  }
  @media (min-width: 901px) and (max-width: 1279px) {
    padding: 20px 40px 45px 25px !important;
    width: 100%;
    .BlogContainer {
      display: block;

      gap: 50px !important;
      .BlogContentContainer {
        width: 100%;
        .InfoTitle-Large {
          font-size: 35px;
        }
      }
    }

    .RelatedContentContainer {
      max-width: unset;
      padding-top: 30px;
      .Mapped-Card-Wrapper {
        display: flex;
        flex-flow: wrap;
        .Article-Card-Style {
          width: 50%;
        }
      }
    }
  }
  @media (min-width: 1280px) and (max-width: 1365px) {
    padding: 30px 80px 65px 60px !important;
    width: 100%;

    .BlogContainer {
      gap: 80px !important;
      .BlogContentContainer {
        width: calc(100% - 260px);
      }
    }

    .RelatedContentContainer {
      max-width: 260px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1599px) {
    padding: 30px 80px 65px 60px !important;
    width: 100%;
    .BlogContainer {
      gap: 80px !important;
    }
  }
  @media (min-width: 1600px) and (max-width: 1919px) {
    width: 90%;
  }
`;
export const PageTitle = styled.p`
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 0px;
  color: #000000;
`;

export const EmptyResultMessageRecord = styled.p`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #13140f;
  text-align: center;
`;

export const ArticleFiltersContainer = styled.div`
  display: flex;
  gap: 8px;
  scroll-behavior: smooth;
  overflow: auto;
  cursor: pointer;
  margin-top: 48px;
`;
export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(356px, 1fr));
  gap: 15px;
  padding: 32px 0 0;
`;

export const BlogsInfoWrapper = styled.div``;

export const BlogContentContainer = styled.div`
  margin: 0;
  font-family: "Schibsted Grotesk";
  width: calc(100% - 300px);
  max-width: 1000px;
`;

export const InfoTitle = styled.h1`
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 12px;
  line-height: 59.25px;
  color: #000;
  cursor: default;
  font-family: "Schibsted Grotesk";
`;

export const AuthorName = styled.p`
  font-size: 16px;
  color: #666;
  margin: 24px 0 40px;
  color: #425889;
  font-weight: 700;
  text-transform: capitalize;
  cursor: default;
  font-family: "Schibsted Grotesk";
`;
export const SubTitle = styled.p`
  font-size: 24px;
  margin-bottom: 32px;
  color: #666;
  margin: 0;
  color: #000000;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "Schibsted Grotesk";
`;
export const InfoDate = styled.p`
  font-size: 12px;
  color: #cccccc;
  font-weight: 700;
  font-family: "Schibsted Grotesk";
  padding: 40px 0;
  margin: 0px;
  cursor: default;
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  font-family: "Schibsted Grotesk";
  cursor: default;
`;

export const Section = styled.section`
  margin: 32px 0;
`;

export const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #000;
  font-family: "Schibsted Grotesk";
`;

export const FinishButton = styled.button`
  background-color: #425889;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: 800;
  cursor: pointer;
  margin-top: 32px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 14px;
  color: #ffffff;
  font-family: "Schibsted Grotesk";
  width: 100%;
`;
export const CardImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  max-height: 480px;
`;
export const BreadCrumb = styled.p`
  color: #999999;
  font-size: 14px;
  font-weight: 700;
  font-family: "DM Sans";
  text-transform: uppercase;
  line-height: 19.6px;
  letter-spacing: 1%;
  cursor: default;
  span {
    &:nth-child(1) {
      cursor: pointer !important;
    }

    &:hover {
      color: #425889;
    }
  }
`;
export const RelatedContentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 300px;
  row-gap: 16px;
  display: grid;
`;
export const BlogContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  display: flex;
  padding: 24px 0px 20px;
  gap: 20%;
`;
