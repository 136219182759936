import styled from "styled-components";

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;
export const PageTitle = styled.p`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #000000;
  cursor: default;
  font-family: "Schibsted Grotesk";
`;
