import { createGlobalStyle } from "styled-components";
import GilroyRegularWoff from "./assets/fonts/Gilroy-Regular.woff";
import GilroyMediumWoff from "./assets/fonts/Gilroy-Medium.woff";
import GilroySemiBoldWoff from "./assets/fonts/Gilroy-SemiBold.woff";
import GilroyBoldWoff from "./assets/fonts/Gilroy-Bold.woff";
import MetropolisBlack from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-Black.otf";
import MetropolisBold from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-Bold.otf";
import MetropolisRegular from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-Regular.otf";
import MetropolisExtraBold from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-ExtraBold.otf";
import "@fontsource/schibsted-grotesk"; // Defaults to weight 400
import "@fontsource/schibsted-grotesk/400.css"; // Specify weight
import "@fontsource/schibsted-grotesk/400-italic.css";
import "@fontsource/dm-sans"; // Defaults to weight 400
import "@fontsource/dm-sans/400.css"; // Specify weight
import "@fontsource/dm-sans/400-italic.css"; // Specify weight and style
import "@fontsource/manrope"; // Defaults to weight 400
import "@fontsource/manrope/400.css"; // Specify weight

const GlobalStyle = createGlobalStyle`
  input[type=text]:focus {
    outline: none !important;
    outline-offset: 2px !important; 
  }

  input[type=text]{
    outline: none !important;
  }

  @font-face {
    font-family: 'Metropolis ExtraBold';
    src: local('Metropolis ExtraBold'), url(${MetropolisExtraBold}) format('opentype');   
  }

  // Metropolis
  @font-face {
    font-family: 'Metropolis Regular';
    src: local('Metropolis Regular'), url(${MetropolisRegular}) format('opentype');   
  }


  // Nexa Bold
  @font-face {
    font-family: 'Metropolis Bold';
    src: local('Metropolis Bold'), url(${MetropolisBold}) format('opentype');   
  }

  // NexaBlack
  @font-face {
    font-family: 'Metropolis Black';
    src: local('Metropolis Black'), url(${MetropolisBlack}) format('opentype');   
  }

  @font-face {
      font-family: 'Gilroy Regular';
      src: local('Gilroy Regular'), url(${GilroyRegularWoff}) format('woff');   
    }

  @font-face {
      font-family: 'Gilroy Medium';
      src: local('Gilroy Medium'), url(${GilroyMediumWoff}) format('woff');     
    }
  @font-face {
      font-family: 'Gilroy SemiBold';
      src: local('Gilroy SemiBold'), url(${GilroySemiBoldWoff}) format('woff');    
    }
  
  @font-face {
      font-family: 'Gilroy Bold';
      src: local('Gilroy Bold'), url(${GilroyBoldWoff}) format('woff');
    }

  .ant-picker-footer {
    display: none ;
  }
  .Add-Btn-Popup-Style {
    .ant-modal-close-icon {
      svg {
        font-size: 19px;
        color: rgba(0, 0, 0, 1);
      }
    }
    .ant-modal-footer {
      padding: 48px 30px 30px;
      .ant-btn-primary {
        background-color: rgba(66, 88, 137, 1) !important;
        font-family: "Schibsted Grotesk";
        font-size: 14px;
        font-weight: 800;
      }
    }
  }
  .Account-Deletion-Modal-Style{
    width: 100% !important;
    max-width: 370px;
    .ant-modal-content{
      display: flex;
      flex-direction: column;
    }
    .ant-modal-header{
      padding: 26px 24px 34px;
      .ant-modal-title {
      font-size: 16px;
      font-family: "Schibsted Grotesk";
      color: rgba(0, 0, 0, 1);
      font-weight: 700;
    }
    }
    .ant-modal-close{
      top: 10px;
      right: 2px;
      svg{
        fill:rgba(0, 0, 0, 1);
      }
    }
    .ant-modal-body{
      padding: 0 24px;
    }
    .ant-modal-footer{
      padding: 16px 24px 24px;
     .ant-btn{
      border:2px solid rgba(194, 70, 70, 1);
      color: rgba(194, 70, 70, 1);
      font-size: 14px;
      font-weight: 800;
      font-family: "Schibsted Grotesk";
      background-color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      text-transform: uppercase;
      height: 48px;
      &:hover, &:active, &:focus{
        opacity: 1;
      }

     }
    }
  &.Deletion-Confirmed-Style{
    .ant-btn {
      background-color: rgba(66, 88, 137, 1);
      color: rgba(255, 255, 255, 1);
      border: 2px solid rgba(66, 88, 137, 1);
    }
  }

  }
  .Logout-Modal-Style{
    width: 100% !important;
    max-width: 370px;
    .ant-modal-content{
      display: flex;
      flex-direction: column;
      .ant-modal-body{
        p{
          text-align: left;
          color: rgba(153, 153, 153, 1);
          font-size: 16px;
          font-weight: 500;
          font-family: "DM Sans";
          margin: 0px;
        }
 
      }
    }
    .ant-modal-footer{
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 20px;
      >div{
        width: 100%;
        height: 48px;
        .ant-btn{
          width: 100%;
          font-size: 14px;
          font-weight: 800;
          border-radius: 4px;
          text-transform: uppercase;
          font-family: "Schibsted Grotesk";
        }
        &:nth-child(1){
          .ant-btn{
            background-color: rgba(244, 247, 252, 1);
            color: rgba(66, 88, 137, 1);
            border: 1px solid rgba(244, 247, 252, 1);
          }
        }
        &:nth-child(2){
          .ant-btn{
            background-color: rgba(255, 255, 255, 1);
            color: rgba(194, 70, 70, 1);
            border: 2px solid rgba(194, 70, 70, 1);
          }
        }
      }
    }
    .ant-modal-header{
      padding: 26px 24px 34px;
      .ant-modal-title {
      font-size: 16px;
      font-family: "Schibsted Grotesk";
      color: rgba(0, 0, 0, 1);
      font-weight: 700;
    }
    }
    .ant-modal-close{
      top: 10px;
      right: 2px;
      svg{
        fill:rgba(0, 0, 0, 1);
      }
    }
  }
  .ant-picker-time-panel-column::after{
    height: 0;
  }

  .ant-dropdown-menu{
    position: absolute;
    right: -21px;
    max-height: 250px;
    overflow: auto;
    font-family: "Gilroy Medium"   ;
    & > li > a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  div > .anticon-loading, main > .anticon-loading{
    color: #4385C6;
  }

  .ant-popover-inner{
    border-radius: 10px !important;
  }

  .ant-popover-inner-content{
    padding: 12px 0 !important;
  }

  .app-action-dropdown{
    top: 52px !important;
    left: calc(100vw - 290px)  !important;
  }

`;
export default GlobalStyle;
